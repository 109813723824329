<template>
  <div>
    <el-card>
      <nav class="input" style="width: 150px">
        <el-button type="primary" size="small" :disabled="!($store.state.btn_if.indexOf('cooperation_white_add') != -1)" @click="add">{{
          '添加白名单用户'
        }}</el-button>
      </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <el-table-column prop="userId" label="头像" width="200">
          <template slot-scope="{row}">
            <el-image :src="row.avatar" alt="" style="width:80px">
              <div slot="error">
                <img :src="baseurl+yu+'/static/pc/img/avatar.png'" alt="" style="width:80px">
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" />
        <el-table-column prop="realName" label="姓名" />
        <el-table-column prop="createTime" label="添加时间" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button
              v-show="$store.state.btn_if.indexOf('cooperation_white_del') != -1"
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
              @click="open(row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="this.search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="this.search.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <Dialog ref="dialog" :title="'添加用户白名单'" :cet="true" @getlist="getlist" />
  </div>
</template>

<script>
let that
// import rules from '../../utils/rules'
import mixins from '../../mixin/list'
export default {
  components: {
    Dialog: () => import('@/components/popup.vue')
  },
  mixins: [mixins],
  data() {
    return {
      btn: true,
      action: {
        action: 'cooperation'
      },
      pagesize: 10,
      search: {
        page: 1,
        pageSize: 10
      },
      s_form: {
        page: 1,
        pageSize: 10,
        sendUser: '',
        receiveUser: '',
        status: '',
        createDate: []
        // order: ''
      }
    }
  },
  computed: {

  },
  beforeCreate() {
    that = this
  },
  updated() {
    //   console.log(this.form);
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.getlist()
    sessionStorage.setItem('action', 'cooperation')
  },
  mounted() {
    // console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
  },
  methods: {
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },

    getlist(val) {
      var form = val || this.search
      this.$http
        .get('/admin/Cooperation/getWhiteList', {
          params: {
            ...form
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.total = res.data.totalCount
          } 
        })
    },

    async add() {
      this.$refs.dialog.dialogVisible = true
    },

    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .post('/admin/Cooperation/delWhiteList', { id: id })
            .then(res => {
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1 && this.total != 1) {
                  this.search.page--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.box {
  display: inline-block;
  margin: 5px;
}
</style>
